import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import ContentBlockParser from "./ContentBlockParser"
import styles from "./content-block.module.scss"
const ContentBlocks = ({
  content,
  meta,
  whereWeServiceActive,
  isPost,
  isHome,
  isLandingPage,
}) => {
  const {
    allKontentItemPage,
    allKontentItemPest,
    allKontentItemPost,
    allKontentItemPostCopy,
    allKontentItemServiceArea,
  } = useStaticQuery(getSlugs)
  const pageTypes = {}
  allKontentItemPage.nodes.forEach(({ elements }) => {
    const { slug } = elements
    pageTypes[slug.value] = "page"
  })
  allKontentItemPest.nodes.forEach(({ elements }) => {
    const { slug } = elements
    pageTypes[slug.value] = "pest"
  })
  allKontentItemPost.nodes.forEach(({ elements }) => {
    const { slug } = elements
    pageTypes[slug.value] = "post"
  })
  allKontentItemPostCopy.nodes.forEach(({ elements }) => {
    const { slug } = elements
    pageTypes[slug.value] = "post"
  })
  allKontentItemServiceArea.nodes.forEach(({ elements }) => {
    const { slug } = elements
    pageTypes[slug.value] = "serviceArea"
  })

  const numBlocks = content.value.length
  //ContentBlocks is a stepping stone element that does the work of splitting up each type of content block possible.
  return (
    <article>
      {content.value.map(({ id, elements }, i) => {
        const noPadding =
          content.value[i].__typename === "kontent_item_review_block"
            ? true
            : false
        const landingForm =
          content.value[i].__typename === "kontent_item_form_banner"
            ? true
            : false
        let className
        if (
          i === 0 &&
          numBlocks > 0 &&
          !isPost &&
          !isHome &&
          !noPadding &&
          !landingForm
        ) {
          elements.alt_background_color !== undefined &&
          elements.alt_background_color.value[0] !== undefined &&
          elements.alt_background_color.value[0].codename === "yes"
            ? (className = `clearfix ${styles.firstBlock} ${styles.contentBlock} ${styles.noMarginTop}`)
            : (className = `clearfix ${styles.firstBlock} ${styles.contentBlock} `)
        } else if (i === 0 && isHome) {
          className = `clearfix ${styles.contentBlock} ${styles.navTiles} `
        } else if (i === 0 && isPost) {
          className = `clearfix ${styles.contentBlock} ${styles.firstBlock}`
        } else if (i === 1 && isHome && !isLandingPage) {
          className = `clearfix ${styles.contentBlock} ${styles.firstBlock}`
        } else if (
          i === numBlocks - 1 &&
          numBlocks > 1 &&
          !isPost &&
          !isLandingPage
        ) {
          className = `clearfix ${styles.contentBlock} ${styles.lastBlock}`
        } else if (noPadding) {
          className = `${styles.reviewBlock} ${
            isLandingPage ? content.value[i].__typename : ""
          }`
        } else if (isLandingPage && landingForm) {
          className = styles.landingForm
        } else {
          className = `clearfix ${styles.contentBlock}`
        }
        return (
          <div
            key={id}
            className={
              !elements.google_url &&
              (elements.alt_background_color !== undefined &&
              elements.alt_background_color.value[0] !== undefined &&
              elements.alt_background_color.value[0].codename === "yes"
                ? `${styles.altBg} ${className}`
                : `${className}`)
            }
          >
            <ContentBlockParser
              blocks={elements}
              pageTypes={pageTypes}
              meta={meta}
              whereWeServiceActive={whereWeServiceActive}
              position={i}
              blockCount={numBlocks}
              isPost={isPost}
              isLandingPage={isLandingPage}
            />
          </div>
        )
      })}
    </article>
  )
}

const getSlugs = graphql`
  query allSlugs {
    allKontentItemPage {
      nodes {
        elements {
          slug {
            value
          }
        }
      }
    }
    allKontentItemPest {
      nodes {
        elements {
          slug {
            value
          }
        }
      }
    }
    allKontentItemPost {
      nodes {
        elements {
          slug {
            value
          }
        }
      }
    }
    allKontentItemPostCopy {
      nodes {
        elements {
          slug {
            value
          }
        }
      }
    }
    allKontentItemServiceArea(
      filter: { elements: { slug: { value: { ne: "" } } } }
    ) {
      nodes {
        elements {
          slug {
            value
          }
        }
      }
    }
  }
`

export default ContentBlocks
