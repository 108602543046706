import React, { useContext } from "react"
import { AppContext } from "../context/context"
import { Link } from "gatsby"
import Img from "gatsby-image"
import Gallery from "../image-gallery/Gallery"
import styles from "./content-block.module.scss"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import InnerHTML from "dangerously-set-html-content"
import { Content } from "../content/Content"

const BasicContentBlock = ({
  pageTypes,
  content,
  heading,
  subHeading,
  html,
  image,
  image_gallery,
  alt_background_color,
  position,
  buttons,
  blockCount,
  meta,
  isPost,
}) => {
  let location

  if (typeof meta === "object") {
    location = meta.breadcrumb.location
  } else {
    location = "/"
  }

  const {
    whereWeServiceActive,
    whereWeServiceSlug,
    blogParent,
    pestLibraryActive,
    pestLibrarySlug,
  } = useContext(AppContext)

  const getInnerGallery = () => {
    if (image_gallery && image_gallery.value[0]) {
      return (
        <section className="container">
          <Gallery images={image_gallery.value[0].elements.gallery} />
        </section>
      )
    }
  }
  const getButtons = () => {
    if (buttons && buttons.value.length) {
      return (
        <div className={styles.buttonContainer}>
          {buttons.value.map(({ id, elements }, i) => {
            const { button_text, linked_page, anchor_reference } = elements
            if (linked_page) {
              const { slug } = linked_page.value[0].elements
              return (
                <Link
                  key={id}
                  to={`/${slug.value}`}
                  className={i === 0 ? "accent-button" : "solid-primary-button"}
                >
                  {button_text.value}
                </Link>
              )
            } else {
              return (
                <AnchorLink
                  key={id}
                  to={`${location}#${anchor_reference.value[0].name ? anchor_reference.value[0].name : 'form-banner'}`}
                  className={i === 0 ? "accent-button" : "solid-primary-button"}
                >
                  {button_text.value}
                </AnchorLink>
              )
            }
          })}
        </div>
      )
    }
  }

  return (
    <>
      {heading && heading.value !== "" && <h2>{heading.value}</h2>}
      {subHeading && subHeading.value !== "" && <h3>{subHeading.value}</h3>}
      {image && image.value.length > 0 && (
        <Img
          fluid={image.value[0].fluid}
          className={styles.interiorContentImage}
          alt={image.value[0].description}
        />
      )}
      {html &&
        html.value &&
        (html.value.includes("afn-container") ||
          html.value.includes("bpco-container")) && (
          <InnerHTML
            className={styles.embedded}
            contentEditable="false"
            html={html.value}
          ></InnerHTML>
        )}
      {content?.value !== "<p><br></p>" && (
        <Content
          content={content}
          pageTypes={pageTypes}
          styles={styles}
          whereWeServiceActive={whereWeServiceActive}
          whereWeServiceSlug={whereWeServiceSlug}
          pestLibraryActive={pestLibraryActive}
          pestLibrarySlug={pestLibrarySlug}
          blogParent={blogParent}
        />
      )}

      {getInnerGallery()}

      {html.value &&
        !html.value.includes("afn-container") &&
        !html.value.includes("bpco-container") && (
          <div
            className={styles.embeddedVideo}
            contentEditable="false"
            dangerouslySetInnerHTML={{ __html: html.value }}
          ></div>
        )}
      {buttons && getButtons()}
    </>
  )
}

export default BasicContentBlock
