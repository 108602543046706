import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useRef,
} from "react"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import { RichTextElement } from "@kentico/gatsby-kontent-components"
import { AppContext } from "../context/context"
import formStyles from "./form-styles.module.scss"
import { appendScript, PestroutesHelper } from "../../assets/js/helper"
import { FieldsParser } from "./FieldsParser"
import styles from "./form-styles.module.scss"
const FormBuilder = ({
  heading,
  subHeading,
  form,
  formGridStyle,
  phone,
  isSubHeadingLeftAligned,
  isLandingPage = false,
}) => {
  const subHeadingLeftAligned = isSubHeadingLeftAligned
    ? "sub-heading-left-aligned"
    : ""

  const formRef = useRef(null)
  const {
    fullName,
    email,
    siteUrl,
    recapKey,
    scriptsLoaded,
    setScriptsLoaded,
  } = useContext(AppContext)

  const [error, setError] = useState(false)
  const [firstRender, setFirstRender] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [formSubmitting, setSubmitting] = useState(false)

  const { fields, submission_message_heading, submission_message_body } =
    form.value[0].elements
  const { submit_button_text } = form.value[0].elements
  const initialFormValues = {}
  const validationObject = {}
  const yesterday = new Date(Date.now() - 86400000)

  const reCaptchaFocus = useCallback(() => {
    appendScript({
      id: "recaptcha",
      scriptToAppend: `https://www.google.com/recaptcha/api.js?render=${recapKey}`,
      isAsync: true,
    })

    // remove focus to avoid js error:
    // Uncaught Error: reCAPTCHA has already been rendered in this element at Object.kh
    formRef.current &&
      formRef.current.removeEventListener("focus", reCaptchaFocus, true)

    setScriptsLoaded(true)
  }, [recapKey, formRef, setScriptsLoaded])

  useEffect(() => {
    if (firstRender && !scriptsLoaded && !window.grecaptcha) {
      formRef.current &&
        formRef.current.addEventListener("focus", reCaptchaFocus, true)
    } else {
      setFirstRender(true)
    }
  }, [scriptsLoaded, firstRender, reCaptchaFocus])

  const buildValidationSchema = (field, required) => {
    switch (field) {
      case "preferred_date":
        required === "yes"
          ? (validationObject[field] = Yup.date()
              .min(yesterday, "Date cannot be in the past")
              .required("Required"))
          : (validationObject[field] = Yup.date().min(
              yesterday,
              "Date cannot be in the past"
            ))
        break
      case "email":
        required === "yes"
          ? (validationObject[field] = Yup.string()
              .email("invalid email address")
              .required("Required"))
          : (validationObject[field] = Yup.string().email(
              "invalid email address"
            ))
        break
      case "phone":
        required === "yes"
          ? (validationObject[field] = Yup.string()
              .matches(
                /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                "Please Enter A valid Phone Number"
              )
              .required("Required"))
          : (validationObject[field] = Yup.string().matches(
              /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
              "Please Enter A valid Phone Number"
            ))
        break
      case "zip":
        required === "yes"
          ? (validationObject[field] = Yup.string()
              .matches(/^\d{5}$/, "Please enter a valid zip code")
              .required("Required"))
          : (validationObject[field] = Yup.string().matches(
              /^\d{5}$/,
              "Please enter a valid zip code"
            ))
        break
      default:
        required === "yes"
          ? (validationObject[field] = Yup.string().required("Required"))
          : (validationObject[field] = Yup.string())
        break
    }
  }

  fields.value.length > 0 &&
    fields.value.forEach(field => {
      const label = field.elements?.label
      const form_field =
        field?.elements?.form_field?.value.length > 0
          ? field.elements.form_field.value[0]?.codename
          : ""
      const required =
        field?.elements?.required?.value.length > 0
          ? field.elements.required.value[0]?.codename
          : ""
      const hiddenFieldValue = field?.elements?.hidden_field_value?.value
      const customValue = label?.value?.replace(/\s+/g, "")
      buildValidationSchema(form_field, required)

      switch (form_field) {
        case "full_name":
          initialFormValues[form_field] = fullName
          break
        case "email":
          initialFormValues[form_field] = email
          break
        case "custom":
          initialFormValues[customValue] = hiddenFieldValue ?? ""
          break
        default:
          initialFormValues[form_field] = hiddenFieldValue ?? ""
          break
      }
    })

  const handleOnSubmit = async values => {
    if (!formSubmitting) {
      setSubmitting(true)
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(recapKey, { action: "submit" })
          .then(token => {
            let myHeaders = new Headers()
            myHeaders.append("Content-Type", "text/plain")
            myHeaders.append("Referer", `${siteUrl}`)
            let raw = JSON.stringify({
              ...values,
              "google-recaptcha-token": token,
              campaignTracking: {
                source: PestroutesHelper.getCookie("utm_source"),
                campaign: PestroutesHelper.getCookie("utm_campaign"),
                medium: PestroutesHelper.getCookie("utm_medium"),
                term: PestroutesHelper.getCookie("utm_term"),
                content: PestroutesHelper.getCookie("utm_content"),
              },
            })
            let params = {
              method: "POST",
              headers: myHeaders,
              body: raw,
              redirect: "follow",
            }
            fetch(
              "https://control-prod.lobstermarketing.com/lobster-sites/contact-us",
              params
            )
              .then(response => response)
              .then(response => {
                if (
                  parseInt(response.status) >= 200 &&
                  parseInt(response.status) < 300
                ) {
                  console.log("Lead Success: Status ", response.status)
                  setFormSubmitted(true)
                  setSubmitting(false)
                  setError(false)
                  if (window.dataLayer) {
                    window.dataLayer.push({'event':'FormSubmitConversionEvent',});
                  }
                } else {
                  console.log("status", response.status)
                  console.log("error", response)
                  setError(true)
                  setSubmitting(false)
                }
              })
              .catch(error => {
                console.log("error", error)
                setError(true)
                setSubmitting(false)
              })
          })
          .catch(error => {
            console.log("error", error)
            setError(true)
            setSubmitting(false)
          })
      })
    }
    // fetch("https://lobster-api.netlify.app/api/acform", params)
    //   .then(response => response.json())
    //   .then(response => {
    //     console.log(response)
    //     if (response.success) {
    //       setFormSubmitted(true)
    //       setSubmitting(false)
    //     } else {
    //       setError(response.msg)
    //     }
    //   })
    //   .catch(e => console.log(e))
  }

  const submissionMessageBody = submission_message_body?.value
  const submissionMessageHeading = submission_message_heading?.value

  return (
    <>
      <h2>{heading.value}</h2>
      <div
        className={`form-inner ${
          isLandingPage === true ? styles.landingFormInner : ""
        } container ${formSubmitted ? formStyles.hideForm : ""}`}
        id="form-banner"
      >
        {error && (
          <div className={formStyles.formError} id="form-error">
            <h2>Form Error</h2>
            <p>
              Our apologies, our system has encountered an error. Please call{" "}
              <a href={`tel:${phone}`} className={formStyles.phone}>
                {phone}
              </a>{" "}
              to complete your submission.
            </p>
          </div>
        )}
        <p className={subHeadingLeftAligned}>{subHeading.value}</p>
        <Formik
          enableReinitialize={true}
          initialValues={initialFormValues}
          validationSchema={Yup.object(validationObject)}
          onSubmit={values => handleOnSubmit(values)}
        >
          <Form ref={formRef} className={`${formGridStyle}`}>
            {fields.value.map(({ elements }, i) => {
              const fieldType =
                elements?.field_type?.value.length > 0
                  ? elements.field_type.value[0]?.codename
                  : ""
              return (
                <FieldsParser
                  key={`field-${i}`}
                  fieldType={fieldType}
                  elements={elements}
                  arrayIndex={i}
                />
              )
            })}
            <button
              type="submit"
              className={`accent-button ${formStyles.formButton}`}
            >
              {!formSubmitting ? submit_button_text.value : "Submitting..."}
            </button>

            {isLandingPage && (
              <>
                <p className={formStyles.formCtaText}>Or Call Today</p>
                <a href={`tel:${phone}`} className={formStyles.formPhoneNumber}>
                  {phone}
                </a>
              </>
            )}
          </Form>
        </Formik>
      </div>
      {formSubmitted && (
        <div className="section-padding" id="form-submitted">
          <h2>
            {!submission_message_heading || submissionMessageHeading === ""
              ? "Thank You"
              : submissionMessageHeading}
          </h2>
          <div style={{ textAlign: "center" }}>
            {!submission_message_body || submissionMessageBody === "" ? (
              "Your Message has Been received"
            ) : (
              <RichTextElement value={submissionMessageBody} />
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default FormBuilder
