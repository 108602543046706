import React, { useContext } from "react"
import { Link } from "gatsby"
import { RichTextElement } from "@kentico/gatsby-kontent-components"
import Img from "gatsby-image"
import styles from "./faq.module.scss"
import { AppContext } from "../context/context"

const FAQ = ({ question, answer, image, pageTypes }) => {
  const {
    pestLibraryActive,
    pestLibrarySlug,
    whereWeServiceActive,
    whereWeServiceSlug,
    blogParent,
  } = useContext(AppContext)
  const faqAnswer = () => {
    if (answer.links && answer.links.length > 0) {
      return (
        <RichTextElement
          value={answer.value}
          links={answer.links}
          resolveLink={(link, domNode) => {
            switch (pageTypes[link.url_slug]) {
              case "pest":
                const slug = pestLibraryActive
                  ? `/${pestLibrarySlug}/${link.url_slug}`
                  : `/${link.url_slug}`

                return <Link to={slug}>{domNode.children[0].data}</Link>
              case "post":
                return (
                  <Link to={`/blog/${blogParent}${link.url_slug}`}>
                    {domNode.children[0].data}
                  </Link>
                )
              case "serviceArea":
                const serviceSlug =
                  whereWeServiceActive === "true"
                    ? `/${whereWeServiceSlug}/${link.url_slug}`
                    : `/${link.url_slug}`
                return <Link to={serviceSlug}>{domNode.children[0].data}</Link>
              case "page":
              default:
                return (
                  <Link to={`/${link.url_slug}`}>
                    {domNode.children[0].data}
                  </Link>
                )
            }
          }}
        />
      )
    } else {
      return <RichTextElement value={answer.value} />
    }
  }
  return (
    <div
      itemScope
      itemProp="mainEntity"
      itemType="https://schema.org/Question"
      className={styles.faq}
    >
      <h3 className={styles.question} itemProp="name">
        {question.value}
      </h3>
      <div
        itemScope
        itemProp="acceptedAnswer"
        itemType="https://schema.org/Answer"
        className={styles.answer}
      >
        <div itemProp="text">{faqAnswer()}</div>
      </div>
      {image.value[0] !== undefined ? (
        <Img
          fluid={image.value[0].fluid}
          className={styles.image}
          alt={image.value[0].description}
        />
      ) : (
        ""
      )}
    </div>
  )
}

export default FAQ
