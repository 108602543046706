import React, { useContext } from "react"
import styles from "./blog.module.scss"
import { AppContext } from "../context/context"
import Img from "gatsby-image"
import { Content } from "../content/Content"

const BlogPostV2 = ({
  main_content_section,
  first_content_section,
  featured_image,
  pageTypes,
}) => {
  const {
    whereWeServiceActive,
    whereWeServiceSlug,
    blogParent,
    pestLibraryActive,
    pestLibrarySlug,
  } = useContext(AppContext)

  return (
    <div className={styles.v2}>
      {first_content_section?.value !== "<p><br></p>" && (
        <Content
          content={first_content_section}
          pageTypes={pageTypes}
          styles={styles}
          whereWeServiceActive={whereWeServiceActive}
          whereWeServiceSlug={whereWeServiceSlug}
          pestLibraryActive={pestLibraryActive}
          pestLibrarySlug={pestLibrarySlug}
          blogParent={blogParent}
        />
      )}

      <Img
        fluid={featured_image.value[0].fluid}
        className={styles.postImage}
        alt={featured_image.value[0].description}
      />

      {main_content_section?.value !== "<p><br></p>" && (
        <Content
          content={main_content_section}
          pageTypes={pageTypes}
          styles={styles}
          whereWeServiceActive={whereWeServiceActive}
          whereWeServiceSlug={whereWeServiceSlug}
          pestLibraryActive={pestLibraryActive}
          pestLibrarySlug={pestLibrarySlug}
          blogParent={blogParent}
        />
      )}
    </div>
  )
}

export default BlogPostV2
