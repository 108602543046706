import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import styles from "./service.module.scss"
import { Link } from "gatsby"
const Service = ({ serviceInfo, meta }) => {
  const {
    name,
    icon,
    price,
    price_term,
    button,
    pricing_disclaimer,
    service_highlights,
  } = serviceInfo
  const { anchor_reference, button_text, linked_page  } = button.value[0].elements
  const priceArr = price.value.split(".")

  const { location } = meta.breadcrumb

  return (
    <div className={styles.service}>
      <div className={styles.serviceHeader}>
        <h2>{name.value}</h2>
        {icon.value.length > 0 ? "icon here" : ""}
      </div>
      <div className={`align-center ${styles.pricing}`}>
        Starting at <span className={styles.price}>${priceArr[0]}</span>/
        {price_term.value[0].name}
      </div>
      <div className={styles.serviceHighlights}>
        <p className="primary-color align-center">
          <strong>What's included:</strong>
        </p>
        <ul>
          {service_highlights.value.map(({ elements, id }) => {
            const { heading } = elements
            return (
              <li key={id}>
                <p>{heading.value}</p>
                <hr className={styles.dashedBorder} />
              </li>
            )
          })}
        </ul>
      </div>
      <div className={styles.serviceFooter}>
                {anchor_reference &&
            <AnchorLink
            to={`${location}#${ anchor_reference.value[0].name ? anchor_reference.value[0].name : 'form-banner'}`}
            className="accent-button align-center"
          >
            {button_text.value}
          </AnchorLink>
        }
                {linked_page &&
            <Link to={linked_page.value[0].elements.slug.value} className="accent-button align-center">
            {button_text.value}
          </Link>
        }
        {pricing_disclaimer.value &&
          <p className={`${styles.disclaimer}`}>*{pricing_disclaimer.value}</p>
        }
      </div>
    </div>
  )
}

export default Service
