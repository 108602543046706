import React from "react"
import { Link } from "gatsby"
import { RichTextElement } from "@kentico/gatsby-kontent-components"

export const Content = ({
  content,
  pageTypes,
  styles,
  whereWeServiceActive,
  whereWeServiceSlug,
  pestLibraryActive,
  pestLibrarySlug,
  blogParent,
}) => {
  if (content.links?.length > 0) {
    return (
      <RichTextElement
        value={content.value}
        links={content.links}
        resolveLink={(link, domNode) => {
          if (link) {
            switch (pageTypes[link.url_slug]) {
              case "pest":
                const slug = pestLibraryActive
                  ? `/${pestLibrarySlug}/${link.url_slug}`
                  : `/${link.url_slug}`

                return (
                  <Link to={slug} className={styles.link}>
                    {domNode.children[0].data}
                  </Link>
                )
              case "post":
                return (
                  <Link
                    to={`/blog/${blogParent + link.url_slug}`}
                    className={styles.link}
                  >
                    {domNode.children[0].data}
                  </Link>
                )
              case "serviceArea":
                const serviceSlug =
                  whereWeServiceActive === "true"
                    ? `/${whereWeServiceSlug}/${link.url_slug}`
                    : `/${link.url_slug}`

                return (
                  <Link to={serviceSlug} className={styles.link}>
                    {domNode.children[0].data}
                  </Link>
                )
              case "page":
              default:
                if (domNode.children[0] && domNode.children[0].children) {
                  return (
                    <Link to={`/${link.url_slug}`} className={styles.link}>
                      {domNode.children[0].children[0].data}
                    </Link>
                  )
                } else if (domNode.children[0] && domNode.children[0].data) {
                  return (
                    <Link to={`/${link.url_slug}`} className={styles.link}>
                      {domNode.children[0].data}
                    </Link>
                  )
                }
            }
          } else {
            return ""
          }
        }}
      />
    )
  }

  return <RichTextElement value={content.value} />
}
