import React, { useContext } from "react"
import { AppContext } from "../context/context"
import Img from "gatsby-image"
import styles from "./multi-content-block.module.scss"
import { Content } from "../content/Content"

const MultiContentBlock = ({ blocks, pageTypes }) => {
  const {
    whereWeServiceActive,
    whereWeServiceSlug,
    blogParent,
    pestLibraryActive,
    pestLibrarySlug,
  } = useContext(AppContext)

  const { heading, sub_heading, content, image } = blocks

  return (
    <div className={styles.block}>
      {image.value.length > 0 ? (
        <Img
          className={styles.multiImage}
          fluid={image.value[0].fluid}
          alt={image.value[0].description}
        />
      ) : null}

      {heading.value && <h3 className={styles.heading}>{heading.value}</h3>}
      {sub_heading.value && (
        <h4 className={styles.subHeading}>{sub_heading.value}</h4>
      )}
      <div className="alignCenter">
        {content?.value !== "<p><br></p>" && (
          <Content
            content={content}
            pageTypes={pageTypes}
            styles={styles}
            whereWeServiceActive={whereWeServiceActive}
            whereWeServiceSlug={whereWeServiceSlug}
            pestLibraryActive={pestLibraryActive}
            pestLibrarySlug={pestLibrarySlug}
            blogParent={blogParent}
          />
        )}
      </div>
    </div>
  )
}

export default MultiContentBlock
